<template>
  <!-- 底部导航 -->
  <div>
    <div class="footer">
      <div class="footer-w">
        <ul class="footer-service">
          <li>
            <span class="icon">正</span>
            <div class="text">
              <div class="text-1">正品保证</div>
              <div class="text-2">惠选优品 购物无忧</div>
            </div>
          </li>
          <li>
            <span class="icon">全</span>
            <div class="text">
              <div class="text-1">全国配送</div>
              <div class="text-2">专业服务 精准送达</div>
            </div>
          </li>
          <li>
            <span class="icon">增</span>
            <div class="text">
              <div class="text-1">增票保障</div>
              <div class="text-2">全场商品 保证增票开具</div>
            </div>
          </li>
          <li>
            <span class="icon">专</span>
            <div class="text">
              <div class="text-1">专属价格</div>
              <div class="text-2">企业采购 专享低价</div>
            </div>
          </li>
          <li>
            <span class="icon">七</span>
            <div class="text">
              <div class="text-1">退货保障</div>
              <div class="text-2">所有商品 七天无理由退货</div>
            </div>
          </li>
        </ul>
        <div class="footer-nav">
          <dl class="col-links">
            <dt>购物指南</dt>
            <dd>
              <a target="_blank ">授权证书</a>
            </dd>
            <dd>
              <a target="_blank ">购物流程</a>
            </dd>
            <dd>
              <a target="_blank ">常见问题</a>
            </dd>
          </dl>

          <dl class="col-links">
            <dt>配送方式</dt>
            <dd>
              <a target="_blank ">全国网络</a>
            </dd>
            <dd>
              <a target="_blank ">签收事项</a>
            </dd>
            <dd>
              <a target="_blank ">配送服务</a>
            </dd>
          </dl>

          <dl class="col-links">
            <dt>支付方式</dt>
            <dd>
              <a target="_blank ">在线支付</a>
            </dd>
            <dd>
              <a target="_blank ">货到付款</a>
            </dd>
            <dd>
              <a target="_blank ">公司转账</a>
            </dd>
          </dl>

          <dl class="col-links">
            <dt>售后服务</dt>
            <dd>
              <a target="_blank ">取消订单</a>
            </dd>
            <dd>
              <a target="_blank ">退换货流程</a>
            </dd>
          </dl>

          <dl class="col-links">
            <dt>关于我们</dt>
            <!-- <dd>
                            <a href=" " target="_blank " class="gsname"></a>
            </dd>-->
            <dd>
              <a target="_blank ">销售网络</a>
            </dd>
            <dd>
              <a target="_blank ">加入我们</a>
            </dd>
            <dd>
              <a target="_blank ">开票资料</a>
            </dd>
          </dl>
          <div class="col-contact">
            <div class="til"></div>
            <dl class="qrcode">
              <dt>
                <img :src="configureData.qr" class="gsewm" />
              </dt>
              <dd>
                扫一扫！即刻关注
                <span class="gsname">
                  {{
                  configureData.name
                  }}
                </span>
                <br />
                采购热线：{{ configureData.phone
                }}
                <span class="gsphone"></span>
                <br />售后热线：
                <span class="gsphone">{{ configureData.phone }}</span>
                <br />工作日(9:00-18:00)
              </dd>
            </dl>
          </div>
        </div>
        <div class="site-info">
          <p>
            <a
              :href="configureData.website_links"
              target="_blank "
              class="aHoverStyle"
            >{{ configureData.name }}</a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a href="#" target="_blank ">友情链接</a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a
              href
              target="_blank"
              class="aHoverStyle"
            >联系我们 {{ configureData.phone }}</a>
          </p>
          <p>
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              rel="nofollow"
              class="aHoverStyle"
            >{{ configureData.provider }}</a>&nbsp;
            <a
              class="aHoverStyle"
              :href="configureData.telecom_img"
              target="_blank"
              v-if="configureData.value_added != ''"
            >增值电信业务经营许可证：{{ configureData.value_added }}</a>&nbsp;
            <span v-if="configureData.license_key != ''">
              <img :src="jcImg" alt style="width: 20px; height: 20px" />
              <a
                :href="
                  'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' +
                  configureData.license_key
                "
                target="_blank"
                class="aHoverStyle"
              >{{ configureData.license_key }}</a>
            </span>
          </p>
          <p>
            Copyright © {{new Date().getFullYear()}}
            <span class="gsuname">{{configureData.name}}</span> 版权所有
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfigure } from "@/api/home.js";
import jcImg from "@/assets/jc.png";
import { mapActions } from "vuex";
export default {
  name: "nav-footer",
  data() {
    return { jcImg, configureData: {} };
  },
  created() {
    this.getConfigureData();
  },
  methods: {
    ...mapActions(["saveLogoImg"]),
    getConfigureData() {
      getConfigure({}).then(res => {
        if (res.statusCode == 200) {
          this.configureData = res.data;
          this.saveLogoImg(res.data.logoing);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/* 尾部样式 */
.footer {
  width: 100%;
  min-width: 1230px;
  background: #fff;
  border-top: 1px solid #d2d2d2;
  /* margin-top: 40px; */
  color: #757575;
}
.footer .footer-w {
  width: 1200px;
  margin: 0 auto;
}
.footer .footer-service {
  padding: 50px 0;
  overflow: hidden;
}
.footer .footer-service li:first-child {
  border-left: none;
}
.footer .footer-service li {
  width: 239px;
  height: 38px;
  float: left;
  border-left: 1px solid #d2d2d2;
  text-align: center;
  overflow: hidden;
}
.footer .footer-service li .icon {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 2px solid $colorA;
  border-radius: 2px;
  text-align: center;
  font-size: 24px;
  color: $colorA;
  overflow: hidden;
  vertical-align: top;
}
.footer .footer-service li .text {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 185px;
  vertical-align: top;
  text-align: left;
  margin-left: 2px;
}
.footer .footer-service li .text .text-1 {
  height: 16px;
  line-height: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  overflow: hidden;
}
.footer .footer-service li .text .text-2 {
  height: 16px;
  line-height: 16px;
  font-size: 14px;
  *font-size: 12px;
  color: #757575;
  overflow: hidden;
  margin-top: 6px;
}
.footer .footer-nav {
  padding: 30px 0 20px 24px;
  border-top: 1px solid #e8e8e8;
  overflow: hidden;
}
.footer .footer-nav .col-links {
  width: 161px;
  float: left;
  margin-left: 15px;
}
.footer .footer-nav .col-links dt {
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  overflow: hidden;
  color: #757575;
}
.footer .footer-nav .col-links dd {
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  font-family: "SimSun";
}
.footer a {
  font-size: 12px;
  color: #757575;
}
.footer .site-info {
  line-height: 24px;
  border-top: 1px solid #e8e8e8;
  padding: 20px 0;
  text-align: center;
  overflow: hidden;
  font-size: 12px;
  .aHoverStyle:hover {
    color: $colorA;
  }
}
.footer .footer-nav .col-contact .qrcode dt {
  width: 84px;
  height: 84px;
  float: left;
  overflow: hidden;
  margin-right: 8px;
}
.footer .footer-nav .col-contact .qrcode dt .gsewm {
  width: 100%;
  height: 100%;
}
</style>