<template>
  <!-- 顶部导航 -->
  <div class="header">
    <div class="nav-topbar">
      <div class="container">
        <div class="topbar-menu">
          <a href="javascript:;">欢迎光临{{configureData.name}}</a>
          <a href="javascript:;">服务热线：{{configureData.phone}}</a>
        </div>
        <div class="topbar-user">
          <a href="javascript:;" v-if="username">{{ username }}</a>
          <a href="javascript:;" v-if="!username">
            <a href="javascript:;" @click="goLogin(2)">注册</a>
            <a href="javascript:;" @click="goLogin(1)">登录</a>
          </a>
          <a href="javascript:;" v-if="username" @click="goLogout()">退出</a>
          <a href="javascript:;" @click="toMyOrder()" v-if="username">我的订单</a>
          <a href="javascript:;" v-if="username" @click="goCollection()">我的收藏</a>
          <a class="my-cart" href="javascript:;" @click="goToCart()">购物车({{ cartCount }})</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { removeToken } from "@/utils/auth";
import { getConfigure } from "@/api/home.js";
import cartImg from "@/assets/icon-cart-checked.png";
import NoticeBar from "./Notice";
export default {
  name: "top-header",
  components: { NoticeBar },
  data() {
    return {
      phoneList: [],
      configureData: {},
      cartImg
    };
  },
  mounted() {
    let params = this.$route.params;
    if (params && params.from == "login") {
      this.getCartSum();
    }
  },
  computed: {
    ...mapState(["username", "cartCount"])
  },
  filters: {
    // 过滤器处理价格
    currency(val) {
      if (!val) return "0.00";
      return "￥" + val.toFixed(2) + "元";
    }
  },
  created() {
    this.getConfigureData();
  },
  methods: {
    getConfigureData() {
      getConfigure({}).then(res => {
        if (res.statusCode == 200) {
          this.configureData = res.data;
        }
      });
    },
    // 订单
    toMyOrder() {
      // href="/#/order/list"
      this.$router.push("/order/list");
    },
    // 收藏
    goCollection() {
      this.$router.push("/collection");
    },
    // 登录注册
    goLogin() {
      this.$router.push({ path: "/login", query: { type: type } });
    },
    // 退出
    goLogout() {
      // this.$api.mall.logout().then(() => {
      this.$message.success("退出成功");
      // 清除用户ID
      this.$cookie.set("userId", "", { expires: "-1" });
      // 清除用户名
      this.$store.dispatch("saveUserName", "");
      // 清空购物车数量
      this.$store.dispatch("saveCartCount", 0);
      removeToken();
      // });
    },
    // 获取购物车商品数量
    getCartSum() {
      this.$api.mall.getCartSum({}).then((res = 0) => {
        // 保存到vuex里面
        this.$store.dispatch("saveCartCount", res);
      });
    },

    // 跳转购物车
    goToCart() {
      this.$router.push("/cart");
    }
  }
};
</script>

<style lang="scss" scoped>
// @import "./../assets/scss/base.scss";
// @import "./../assets/scss/config.scss";
.header {
  width: 100%;
  background-color: #333333;
  .nav-topbar {
    width: 1200px;
    height: 39px;
    margin: 0 auto;
    line-height: 39px;
    color: #b0b0b0;
    .container {
      @include flex();
      a {
        display: inline-block;
        color: #b0b0b0;
        margin-right: 17px;
      }
      .my-cart {
        width: 110px;
        background-color: $colorA;
        text-align: center;
        color: #fff;
        margin-right: 0;
        .icon-cart {
          // @include bgImg(16px, 12px, "/imgs/icon-cart-checked.png");
          margin-right: 4px;
        }
      }
    }
  }
}
</style>