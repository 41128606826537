<template>
  <div style="margin-bottom: 20px">
    <div class="list-box">
      <div v-for="item in list" :key="item.id" class="listItem">
        <div class="boxImg" @click="handleToDetail(item.id)">
          <img alt :src="item.img.search('http') != -1 ? item.img : defaultImg" />
        </div>
        <span class="content">
          <span class="title" @click="handleToDetail(item.id)">
            {{
            item.goods_name
            }}
          </span>
          <p class="subStyle">{{item.sub_title}}</p>
          <p class="content_footer">
            <span class="price">￥{{ item.goods_price }}</span>
            <span class="joinCart" @click="handleJoinCart(item)">
              <li class="el-icon-shopping-cart-2"></li>加入购物车
            </span>
          </p>
        </span>
      </div>
    </div>
    <!-- <el-empty :image-size="200" v-else style="width:950px;margin 0 auto"></el-empty> -->
  </div>
</template>

<script>
import { getAddCart } from "@/api/home";
import { cartNum } from "@/api/user";
import defaultImg from "@/assets/default.jpg";
import { getToken } from "@/utils/auth";
export default {
  props: ["list"],
  data() {
    return {
      defaultImg
    };
  },
  methods: {
    handleJoinCart(goodsItem) {
      if (getToken()) {
        const addDatas = {
          goods_id: goodsItem.id,
          goods_name: goodsItem.goods_name,
          goods_img: goodsItem.img,
          goods_price: goodsItem.goods_price,
          goods_sum: 1
        };
        getAddCart(addDatas).then(res => {
          if (res.statusCode == 200) {
            this.$message.success("添加成功");
            cartNum({}).then(res => {
              // 保存到vuex里面
              this.$store.dispatch("saveCartCount", res.data);
            });
          } else {
            this.$message.error("添加失败，请稍后重试");
          }
        });
      } else {
        this.$confirm("此操作需要登录账号, 是否前往登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作"
            });
          });
      }
    },
    handleToDetail(id) {
      let routeData = this.$router.resolve({
        path: "/productDetail",
        query: { id: id }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.list-box {
  width: 990px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  // justify-content: space-between;
  .listItem {
    width: 208px;
    background-color: #ffffff;
    // border: 1px solid rgba(136, 136, 136, 0.3);
    margin-left: 39px;
    box-sizing: border-box;
    text-decoration: none;
    margin-bottom: 30px;
    padding: 10px;
  }
  .listItem:hover {
    // border: 1px solid $colorA;
    // color: $colorA;
    box-shadow: 0 5px 5px #888;
  }
  // .listItem:nth-child(5n) {
  //   margin-right: 0;
  // }
  .boxImg {
    width: 186px;
    height: 150px;
    overflow: hidden;
    img {
      width: 100%;
      height: 150px;
    }
  }
  // img:hover {
  //   -webkit-transform: scale(1.2);
  //   -moz-transform: scale(1.2);
  //   -ms-transform: scale(1.2);
  //   -o-transform: scale(1.2);
  //   transform: scale(1.2);
  //   transition: all 300ms linear;
  // }
  .price {
    text-align: left;
    float: left;
    position: relative;
    top: 10px;
  }
  .content {
    padding: 5px 10px;
    .title {
      padding: 0 15px;
      text-align: left;
      color: black;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
      height: 30px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .title:hover {
      cursor: pointer;
      color: $colorA;
    }
    .subStyle {
      padding: 0 15px;
      text-align: left;
      width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
    }
    .content_footer {
      height: 30px;
      padding: 10px 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        height: 30px;
        font-weight: bold;
        color: $colorA;
      }
      .joinCart {
        width: 80px;
        height: 25px;
        padding: 0 5px;
        border-radius: 4px;
        line-height: 25px;
        text-align: center;
        font-size: 10px;
        color: #888;
        border: 1px solid #e5e5e5;
      }
      .joinCart:hover {
        cursor: pointer;
        background-color: $colorA;
        color: #ffffff;
      }
    }
  }
}
</style>